import * as ChainingDefaultConstants from '../types/ChainingDefault/ChainingDefaultConstants'

export default {
    getChainingObjectClass: (state) => (storeKey: string) => {
        return state.chainingDataByKey[storeKey]?.chainingBasicInfo.objectClass || ''
    },
    getChainingObjectId: (state) => (storeKey: string) => {
        return state.chainingDataByKey[storeKey]?.chainingBasicInfo.objectId || ChainingDefaultConstants.CHAINING_NO_INSERTED_ID
    },
    getChainingSpecialtyId: (state) => (storeKey: string) => {
        return state.chainingDataByKey[storeKey]?.chainingSpecialtyId
    },
    getChainingDefaultSpecialtyId: (state) => (storeKey: string) => {
        return state.chainingDataByKey[storeKey]?.chainingDefaultSpecialtyId
    },
    getChainingGroupId: (state) => (storeKey: string) => {
        return state.chainingDataByKey[storeKey]?.chainingBasicInfo.groupId || ChainingDefaultConstants.CHAINING_NO_INSERTED_ID
    },
    getChainingDrugBase: (state) => (storeKey: string) => {
        return state.chainingDataByKey[storeKey]?.chainingDrugBase.drugBase || ''
    },
    getChainingDrugBaseLanguage: (state) => (storeKey: string) => {
        return state.chainingDataByKey[storeKey]?.chainingDrugBase.drugBaseLanguage || 'fr'
    },
    getChainingTypes: (state) => {
        return state.chainingTypes || []
    },
    getChainingSpecialties: (state) => {
        return state.chainingSpecialties || []
    },
    getChainingCareCatalogs: (state) => (storeKey: string) => {
        return state.chainingDataByKey[storeKey]?.chainingCareCatalogs || []
    },
    getChainingCategories: (state) => (storeKey: string) => {
        return state.chainingDataByKey[storeKey]?.chainingCategories || []
    },
    getChainingCatalog: (state) => (storeKey: string) => {
        return state.chainingDataByKey[storeKey]?.chainingCatalog || []
    },
    getChainingServices: (state) => (storeKey: string) => {
        return state.chainingDataByKey[storeKey]?.chainingServices || []
    },
    getChainingDefault: (state) => (storeKey: string) => {
        return state.chainingDataByKey[storeKey]?.chainingDefault || []
    },
    getAllChainingList: (state) => (storeKey: string) => {
        return state.chainingDataByKey[storeKey]?.allChainingList || []
    },
    getAllChainingWithCatalogs: (state) => (storeKey: string) => {
        return state.chainingDataByKey[storeKey]?.allChainingWithCatalogs || []
    },
    getChainingWithServices: (state) => (storeKey: string) => {
        return state.chainingDataByKey[storeKey]?.chainingWithServices || {}
    },
    getInternalChainings: (state) => (storeKey: string) => {
        return state.chainingDataByKey[storeKey]?.internalChainings || []
    },
    getChainingsFromService: (state) => (storeKey: string) => {
        return state.chainingDataByKey[storeKey]?.chainingsFromService || []
    },
    getVirtualChaining: (state) => (storeKey: string) => {
        return state.chainingDataByKey[storeKey]?.virtualChaining || []
    },
    getChainingLastAssociatedPatientServiceId: (state) => (storeKey: string) => {
        return state.chainingDataByKey[storeKey]?.chainingLastAssociatedPatientServiceId || ChainingDefaultConstants.CHAINING_NO_INSERTED_ID
    },
    getChainingLastPatientServiceId: (state) => (storeKey: string) => {
        return state.chainingDataByKey[storeKey]?.chainingLastPatientServiceId || ChainingDefaultConstants.CHAINING_NO_INSERTED_ID
    },
    getChainingDefaultSpecialtyPreferenceId: (state) => (storeKey: string) => {
        return state.chainingDataByKey[storeKey]?.chainingBasicInfo.chainingDefaultSpecialtyPreferenceId
    },
    getChainingDefaultProviderPreferenceId: (state) => (storeKey: string) => {
        return state.chainingDataByKey[storeKey]?.chainingBasicInfo.chainingDefaultProviderPreferenceId
    },
    getChainingUserId: (state) => (storeKey: string) => {
        return state.chainingDataByKey[storeKey]?.chainingBasicInfo.chainingUserId
    },
    getChainingAdministratedProducts: (state) => (storeKey: string) => {
        return state.chainingDataByKey[storeKey]?.chainingAdministratedProducts || []
    },
    getChainingProviders: (state) => (storeKey: string) => {
        return state.chainingDataByKey[storeKey]?.chainingProviders || []
    },
    getChainingProviderId: (state) => (storeKey: string) => {
        return state.chainingDataByKey[storeKey]?.chainingProviderId || null
    }
}
