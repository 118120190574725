import Chaining from '../classes/Chaining'
import ChainingService from '../classes/ChainingService'
import { ChainingFilter } from './ChainingFilter'
import _ from "lodash"
import * as ChainingDefaultConstants from '../types/ChainingDefault/ChainingDefaultConstants'
import { ServiceKhList } from '../types/ServiceKhList'

export class ChainingKhList {
    static readonly VIRTUAL_ITEM_NUMBER = 5
    static readonly VIRTUAL_ITEM_HEIGHT = 42
    static readonly DEFAULT_ITEM_PER_PAGE = 10

    private openedPanels: number[] = []

    private configuration: ChainingPanels = {
        type: 'KhExpansionPanels',
        slots: {
            noData: 'chainingPanelsNoData'
        },
        children: [],
        selected: false,
        selectedState: 1,
        multiple: true,
        openedPanels: this.openedPanels
    }

    private useVirtual = false

    private itemDisplayed = ChainingKhList.VIRTUAL_ITEM_NUMBER
    private itemHeight = ChainingKhList.VIRTUAL_ITEM_HEIGHT

    constructor(
        private chainings: Chaining[]
    ) {
        this.updateConfiguration(chainings)
    }

    private emptyChainingPanels(keepSelectionStatus= false): void {
        this.openedPanels = this.configuration.openedPanels
        this.configuration = {
            type: 'KhExpansionPanels',
            slots: {
                noData: 'chainingPanelsNoData'
            },
            children: [],
            selected: keepSelectionStatus ? this.configuration.selected : false,
            selectedState: keepSelectionStatus ? this.configuration.selectedState : 1,
            multiple: true,
            openedPanels: this.openedPanels
        }
    }

    /**
     * This method update the internal chaining list and update the configuration structure
     *
     * @param chainings
     * @param filter
     * @param openFirstPanel
     * @param keepSelectedChaining
     */
    public updateConfiguration(chainings: Chaining[], filter?: ChainingFilter, openFirstPanel = false, keepSelectedChaining = false): void {
        this.emptyChainingPanels(keepSelectedChaining)
        // we store all chaining opened
        const openedChainingIds: Chaining[] = this.openedPanels.map( index => {
            return this.chainings[index]
        })
        this.chainings = filter ? this.applyFilters(chainings, filter) : chainings

        // we map opened chainings with their new index
        this.openedPanels = openedChainingIds.map( chaining => this.chainings.indexOf(chaining))

        this.configuration.openedPanels = openFirstPanel ? [0] : this.openedPanels

        this.configuration.children = this.chainings.map( (chaining) => {
            const serviceKhList = new ServiceKhList([], this.useVirtual, this.itemDisplayed, this.itemHeight)
            serviceKhList.updateConfiguration(chaining.services, filter)
            const serviceConfiguration = serviceKhList.getConfiguration()

            serviceConfiguration.data = {
                items: serviceKhList.getServices(),
                selectedIndex: chaining.selectedServices,
                nonSelectableItems: chaining.nonSelectableItems,
                chainingKhListReference: this
            }

            serviceConfiguration.slots = {
                content: 'chainingService',
                noData: 'noDataChainingServiceList',
                checkbox: 'checkbox'
            }

            return {
                type: 'KhExpansionPanel',
                slots: {
                    header: 'chainingHeader'
                },
                data: {
                    chaining: chaining,
                    ownReference: this,
                    serviceKhListReference: serviceKhList
                },
                selected: chaining.selected,
                selectedState: chaining.selectedState,
                content: serviceConfiguration
            } as ChainingPanel
        })
    }

    public getConfiguration(): ChainingPanels {
        return this.configuration
    }

    public getOpenedPanels(): number[] {
        return this.configuration.openedPanels
    }

    public isPanelOpened(indexToCheck: number): boolean {
        return this.configuration.openedPanels.indexOf(indexToCheck) !== -1
    }

    public removeOpenedPanel(indexToRemove: number): void {
        this.openedPanels = this.openedPanels.filter( index => index !== indexToRemove)
    }

    public addOpenedPanels(newIndex: number): void {
        this.configuration.openedPanels.push(newIndex)
    }

    public openAllPanels(): void {
        this.configuration.openedPanels = _.range(
            0,
            this.chainings.length
        )
    }

    public closeAllPanels(): void {
        this.configuration.openedPanels = []
    }

    public getChainings(): Chaining[] {
        return this.chainings
    }

    public setPagination(pagination: boolean = true, itemPerPage: number = ChainingKhList.DEFAULT_ITEM_PER_PAGE){
        this.configuration.pagination = pagination
        this.configuration.itemsPerPage = itemPerPage
    }

    public setVirtualOptions (itemNumber: number, itemHeight: number): void {
        this.itemHeight = itemHeight
        this.itemDisplayed = itemNumber
        this.useVirtual = true
    }

    public applyFilters(chainings: Chaining[], filter?: ChainingFilter): Chaining[] {
        let filteredChainingList: Chaining[] = chainings
        // we do not apply specialty filter if it worth CHAINING_EMPTY_SPECIALTY_ID meaning all specialty
        if (filter?.specialty && filter.specialty !== ChainingDefaultConstants.CHAINING_EMPTY_SPECIALTY_ID) {
            filteredChainingList =  filteredChainingList.filter( (chaining: Chaining) => {
                return chaining.specialtyId == filter?.specialty ||
                    (filter.showAllSpecialty &&
                        (chaining.specialtyId === null ||
                            chaining.specialtyId == ChainingDefaultConstants.CHAINING_EMPTY_SPECIALTY_ID)
                    )
            })
        }
        if (filter?.category) {
            filteredChainingList =  filteredChainingList.filter( (chaining: Chaining) => {
                return chaining.categoryId == filter?.category
            })
        }
        if (filter?.search) {
            filteredChainingList = Chaining.filterByName(filteredChainingList, filter.search)
        }

        return filteredChainingList
    }
}

export interface ChainingPanels {
    type: string
    children: ChainingPanel[]
    openedPanels: number[]
    slots: {}
    multiple: boolean
    selected?: boolean
    selectedState?: number
    pagination?: boolean
    itemsPerPage?: number
}

export interface ChainingPanel {
    type: string
    data: {
        chaining: Chaining,
        ownReference: ChainingKhList,
        serviceKhListReference: ServiceKhList
    }
    slots: {}
    selected: boolean
    selectedState: number
    content: ChainingPanelList | {}
}

export interface ChainingPanelList {
    type: string
    slots: {
        content: string
        noData?: string
        checkbox?: string
    }
    data: {
        items: ChainingService[] | Chaining[]
        selectedIndex: number[]
        nonSelectableItems: number[]
        chainingKhListReference?: ChainingKhList
    }
    virtualOptions?: ChainingListVirtualOptions
}

export interface ChainingListVirtualOptions {
    itemHeight: number
    maxHeight: number
    minHeight: number
}
