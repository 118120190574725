import Vue from 'vue'
import * as ChainingDefaultConstants from '../types/ChainingDefault/ChainingDefaultConstants'
import ChainingSpecialty from '../classes/ChainingSpecialty'
import ChainingCategory from '../classes/ChainingCategory';

export default {
    saveChainingObjectId: (state, args) => {
        state.chainingDataByKey[args.storeKey].chainingBasicInfo.objectId = args.objectId
    },
    saveChainingProviderId: (state, args) => {
        Vue.set(state.chainingDataByKey[args.storeKey], 'chainingProviderId', args.id);
    },
    saveChainingSpecialtyId: (state, args) => {
        Vue.set(state.chainingDataByKey[args.storeKey], 'chainingSpecialtyId', args.id)
    },
    saveChainingUserId: (state, args) => {
        state.chainingDataByKey[args.storeKey].chainingBasicInfo.chainingUserId = args.id
    },
    saveChainingDefaultSpecialtyPreferenceId: (state, args) => {
        state.chainingDataByKey[args.storeKey].chainingBasicInfo.chainingDefaultSpecialtyPreferenceId = args.id
    },
    saveChainingDefaultProviderPreferenceId: (state, args) => {
        state.chainingDataByKey[args.storeKey].chainingBasicInfo.chainingDefaultProviderPreferenceId = args.id
    },
    saveChainingDefaultSpecialtyId: (state, args) => {
        Vue.set(state.chainingDataByKey[args.storeKey], 'chainingDefaultSpecialtyId', args.id)
    },
    saveChainingGroupId: (state, args) => {
        state.chainingDataByKey[args.storeKey].chainingBasicInfo.groupId = args.id
    },
    saveChainingDrugBase: (state, args) => {
        state.chainingDataByKey[args.storeKey].chainingDrugBase.drugBase = args.drugBase
    },
    saveChainingDrugBaseLanguage: (state, args) => {
        state.chainingDataByKey[args.storeKey].chainingDrugBase.drugBaseLanguage = args.drugBaseLanguage
    },
    saveChainingObjectClass: (state, args) => {
        state.chainingDataByKey[args.storeKey].chainingBasicInfo.objectClass = args.objectClass
    },
    saveChainingTypes: (state, types) => {
        Vue.set(state, 'chainingTypes', types)
    },
    saveChainingSpecialties: (state, specialties) => {
        state.chainingSpecialties = specialties;
    },
    saveChainingCareCatalogs: (state, args) => {
        state.chainingDataByKey[args.storeKey].chainingCareCatalogs = args.careCatalogs
    },
    saveChainingCategories: (state, args) => {
        state.chainingDataByKey[args.storeKey].chainingCategories = args.categories
    },
    saveChainingCatalog: (state, args) => {
        state.chainingDataByKey[args.storeKey].chainingCatalog = args.catalog
    },
    saveChainingServices: (state, args) => {
        state.chainingDataByKey[args.storeKey].chainingServices = args.services
    },
    saveChainingDefault: (state, args) => {
        state.chainingDataByKey[args.storeKey].chainingDefault = args.data
    },
    saveChainingManagementCatalogService: (state, args) => {
        state.chainingDataByKey[args.storeKey].chainingManagementCatalogService = args.chaining
    },
    saveAllChainingList: (state, args) => {
        state.chainingDataByKey[args.storeKey].allChainingList = args.list
    },
    saveAllChainingWithCatalogs: (state, args) => {
        state.chainingDataByKey[args.storeKey].allChainingWithCatalogs = args.list
    },
    saveChainingWithServices: (state, args) => {
        state.chainingDataByKey[args.storeKey].chainingWithServices = args.chaining
    },
    saveChainingsFromService: (state, args) => {
        state.chainingDataByKey[args.storeKey].chainingsFromService = args.list
    },
    saveChainingLastAssociatedPatientServiceId: (state, args) => {
        state.chainingDataByKey[args.storeKey].chainingLastAssociatedPatientServiceId = args.id
    },
    saveChainingLastPatientServiceId: (state, args) => {
        state.chainingDataByKey[args.storeKey].chainingLastPatientServiceId = args.id
    },
    saveChainingAdministratedProducts: (state, args) => {
        state.chainingDataByKey[args.storeKey].chainingAdministratedProducts = args.data
    },
    saveChainingProviders: (state, args) => {
        state.chainingDataByKey[args.storeKey].chainingProviders = args.chainingProviders
    },
    addSpecialty: (state, specialty: ChainingSpecialty) => {
        state.chainingSpecialties.push(specialty)
    },
    deleteSpecialty: (state, specialty: ChainingSpecialty) => {
        const specialtyIndex = state.chainingSpecialties.findIndex((item) => item.id === specialty.id)
        state.chainingSpecialties.splice(specialtyIndex, 1)
    },
    addCategory: (state, args) => {
        state.chainingDataByKey[args.storeKey].chainingCategories.push(args.category)
    },
    deleteCategory: (state, args) => {
        const categoryIndex =  state.chainingDataByKey[args.storeKey].chainingCategories
            .findIndex((item) => item.id === args.categoryId)
        state.chainingDataByKey[args.storeKey].chainingCategories.splice(categoryIndex, 1)
    },
    emptyStoreByKey: (state, storeKey) => {
        // @todo must use Vue.delete first when component.destroy() is fixed
        Vue.set(state.chainingDataByKey, storeKey, {
            chainingBasicInfo: {
                objectClass: '',
                objectId: ChainingDefaultConstants.CHAINING_NO_INSERTED_ID,
                groupId: ChainingDefaultConstants.CHAINING_NO_INSERTED_ID,
                chainingUserId: null,
                chainingDefaultSpecialtyPreferenceId: null,
                chainingDefaultProviderPreferenceId: null
            },
            chainingProviders: [],
            chainingProviderId: null,
            chainingSpecialtyId: ChainingDefaultConstants.CHAINING_EMPTY_SPECIALTY_ID,
            chainingDefaultSpecialtyId: ChainingDefaultConstants.CHAINING_EMPTY_SPECIALTY_ID,
            chainingLastAssociatedPatientServiceId: ChainingDefaultConstants.CHAINING_NO_INSERTED_ID,
            chainingLastPatientServiceId: ChainingDefaultConstants.CHAINING_NO_INSERTED_ID,
            chainingAdministratedProducts: [],
            chainingCategories: [],
            chainingCatalog: [],
            chainingServices: [],
            chainingDefault: [],
            allChainingList: [],
            chainingWithServices: [],
            chainingsFromService: [],
            chainingDrugBase: {
                drugBase: 'drug base',
                drugBaseLanguage: 'drug base language'
            },
            chainingManagementCatalogService: [],
            chainingCareCatalogs: [],
            allChainingWithCatalogs: []
        })
    }
}
