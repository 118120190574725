import ChainingService from '../classes/ChainingService'
import { ChainingPanelList } from './ChainingKhList'
import { ChainingFilter } from '../types/ChainingFilter'
import { ChainingPatientServiceStatus } from '../types/ChainingPatientService'

export class ServiceKhList {
    static readonly VIRTUAL_ITEM_NUMBER = 5
    static readonly VIRTUAL_ITEM_HEIGHT = 42

    private configuration: ChainingPanelList = {
        type: 'KhList',
        data: {
            selectedIndex: [],
            items: [],
            nonSelectableItems: []
        },
        slots: {
            content: 'chainingService'
        }
    }

    constructor(
        private services: ChainingService[],
        useVirtual = false,
        itemNumber = ServiceKhList.VIRTUAL_ITEM_NUMBER,
        itemHeight = ServiceKhList.VIRTUAL_ITEM_HEIGHT
    ) {
        if (useVirtual) {
            this.setVirtualOptions(itemNumber, itemHeight)
        }
        this.updateConfiguration(services)
    }


    /**
     * This method update the internal service list and update the configuration structure
     *
     * @param services
     * @param filter
     */
    public updateConfiguration(services: ChainingService[], filter?: ChainingFilter): void {
        const filteredServices = filter ? ServiceKhList.applyFilters(services, filter) : services
        this.configuration.data.items = filteredServices
        this.services = filteredServices
    }

    public static applyFilters(services, filter?: ChainingFilter): ChainingService[] {
        let filteredServices: ChainingService[] = services

        if (filter?.hideValidatedServices) {
            filteredServices = filteredServices.filter(service => service.status === ChainingPatientServiceStatus.pending)
        }

        if (filter?.hideBilledServices) {
            filteredServices = filteredServices.filter(service => service.status !== ChainingPatientServiceStatus.billed)
        }

        if (filter?.keepOnlyOneServiceHealthInsuranceId) {
            filteredServices = filteredServices.filter(service => service.prestationCaisseId == filter.keepOnlyOneServiceHealthInsuranceId)
        }
        return filteredServices
    }

    public reset(): void {
        this.services = []
        this.configuration.data.items = []
        this.configuration.data.nonSelectableItems = []
        this.configuration.data.selectedIndex = []
    }

    public delete(serviceToRemove: ChainingService): ChainingService[] {
        const index = this.services.indexOf(serviceToRemove)
        if (index > -1) {
            this.services.splice(index, 1)
        }
        return this.services
    }

    /**
     * This method is used to synchronize the internal services (this.services)
     * with selectedServices argument
     * It auto select services that are present in both list and returns the ones
     * that are in selectedServices but not in this.services
     *
     * @param selectedServices
     * @return services that are not displayed
     */
    public updateServiceWithSelectedServices(selectedServices: ChainingService[]): ChainingService[] {
        this.configuration.data.items = this.services
        this.configuration.data.selectedIndex = []

        let notDisplayedServices: ChainingService[] = []

        let found = false
        selectedServices.forEach( service => {
            for (const internalService of this.services) {
                if (internalService.prestationCaisseId === service.prestationCaisseId) {
                    this.configuration.data.selectedIndex.push(this.services.indexOf(internalService))
                    found = true
                    break
                }
            }
            if (!found) {
                notDisplayedServices.push(service)
            }
            found = false
        })
        return notDisplayedServices
    }

    public getConfiguration(): ChainingPanelList {
        return this.configuration
    }

    public getServices(): ChainingService[] {
        return this.services
    }

    public setNonSelectableItems(excludedItemIndexes: number[]): void {
        this.configuration.data.nonSelectableItems = excludedItemIndexes
    }

    /**
     * Set the virtualOptions and switch used list to virtual one
     * @param itemNumber
     * @param itemHeight
     */
    public setVirtualOptions (itemNumber: number, itemHeight: number): void {
        this.configuration.type = 'KhVirtualList'
        this.configuration.virtualOptions = {
            minHeight: itemHeight,
            maxHeight: itemHeight * itemNumber,
            itemHeight: itemHeight
        }
    }
}
